import React from "react";
import {Badge, Box, Button, Center, chakra, Flex, Text, useBreakpointValue,} from "@chakra-ui/react";
import {ShareResultButton} from "components/ShareResultButton";
import {useDispatch, useSelector} from "react-redux";
import {updateToelfGid} from "redux/toeflSlice";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {selectUserPreferences} from "../../redux/generalSlice";
import getTimeDescription from "../utils/getTimeDescription";
import {useGetUserPreferencesQuery} from "../../services/cmApi";

function HistoryRowBadge(props) {
    var {visible, color, text, ...other} = props;

    return <Center display={visible ? 'block' : 'none'} {...other}>
        <Badge
            variant='outline' colorScheme={color}
            borderRadius='full'
            px='7px'
        >
            <Center>
                <Text>
                    {text}
                </Text>
            </Center>
        </Badge>
    </Center>

}


export function HistoryRow(props) {
    useGetUserPreferencesQuery({});
    const userPreferences = useSelector(selectUserPreferences);
    const product = userPreferences.product || 'toefl';
    const location = window.location.href;
    const parts = location.split("/");
    const keyword = parts[3];

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const isMobile = useBreakpointValue({base: true, md: false});
    let history = useHistory();
    let task = props.data;
    const section = task.gen_type.includes('writing') ? 'writing' :
        task.gen_type.includes('speaking') ? 'speaking' :
            task.gen_type.includes('reading') ? 'reading' :
                'listening';

    const getTaskName = (genType) => {
        return t(genType);
    };

    return (
        <Flex
            direction={'column'}
            textAlign={'left'}
            justifyContent={'space-between'}
            p={3}
            width="full"
        >
            <Flex direction={'column'} width="full">
                <Flex width="full" gap='5px'>
                    <chakra.p fontWeight={'bold'} fontSize="md" p={0} m={0}>
                        {getTaskName(task.gen_type)}
                    </chakra.p>
                    <HistoryRowBadge
                        visible={task.tpo}
                        color='cyan'
                        text={`${task.gen_type == 'writing_task3' ? t('question_space') : 'TPO '}${task.tpo}`}>
                    </HistoryRowBadge>
                    <HistoryRowBadge
                        visible={task.estimated_score != null}
                        color='green'
                        text={`${t("score")} ${task.estimated_score}`}>
                    </HistoryRowBadge>
                    <HistoryRowBadge
                        visible={task.is_shared}
                        color='blue'
                        text={t("already_shared")}>
                    </HistoryRowBadge>
                    <Box flex={"1"}></Box>
                    <HistoryRowBadge
                        visible={task.status === "CREATED"}
                        color='yellow'
                        text={t('unevaluated')}>
                    </HistoryRowBadge>
                    <HistoryRowBadge
                        visible={task.status === "PENDING"}
                        color='yellow'
                        text={t('under_evaluation')}>
                    </HistoryRowBadge>
                    <HistoryRowBadge
                        visible={task.status === "ERROR"}
                        color='red'
                        text={t('error')}>
                    </HistoryRowBadge>
                    <chakra.p
                        fontWeight={'medium'}
                        color={'gray.500'}
                        p={0} m={0}
                    >
                        {getTimeDescription(t, task.created_at)}
                    </chakra.p>
                </Flex>
            </Flex>
            <Flex direction={"row"}>
                <Text mb={0} mt="5px"
                      fontSize={{base: "sm", md: "15px"}} color="gray.600" noOfLines={3}>
                    {['writing', 'speaking'].includes(section) ? task.summary : t("My accuracy rate") + ' : ' + task.accuracy_rate}
                </Text>
                <Box flex="1" minWidth="10px"></Box>
                <Center>
                    <Flex direction={isMobile ? "column" : "row"} gap="5px">
                        <Button colorScheme='green' borderRadius='20px'
                                size={isMobile ? 'sm' : 'md'}
                                display={task.qid && task.status !== 'PENDING' && (props.balanceValue > 0 || task.tpo === 1) ? 'block' : 'none'}
                                onClick={() => {
                                    console.log(section, "section")
                                    if (['writing', 'speaking'].includes(section)) {
                                        let query = new URLSearchParams();
                                        query.set('qid', task.qid)
                                        console.log(query, "query")
                                        if (task.gen_type.includes('task') && task.status === 'CREATED') {
                                            query.set('gid', task.gid)
                                        }
                                        let pathname = `/${keyword}/${section}/${task.gen_type.includes('generation') ? 'corpus_generation' : 'practice'}`;
                                        history.push(`${pathname}?${query.toString()}`)
                                    } else {
                                        history.push(`/toefl_ibt/${task.tpo || task.order_index}/${section}/${task.qid}`)
                                    }
                                }}
                        >{task.status === 'CREATED' ? t("load") : t("rework")}</Button>
                        <Button
                            colorScheme="blue"
                            borderRadius='20px'
                            size={isMobile ? 'sm' : 'md'}
                            display={task.status === 'SUCCESS' ? 'block' : 'none'}
                            onClick={() => {
                                if (['writing', 'speaking'].includes(section)) {
                                    // dispatch(updateGid(task.gid));
                                    dispatch(updateToelfGid(task.gid))
                                    props.onRowSelected();
                                } else {
                                    const answerJsonString = encodeURIComponent(JSON.stringify(task.answer_json));
                                    history.push(`/toefl_ibt/${task.tpo || task.order_index}/${section}/${task.qid}/answer?answer_json=${answerJsonString}`)
                                }
                            }}>{t('view')}</Button>
                        <ShareResultButton gid={task.gid}
                                           visible={task.status === 'SUCCESS' && task.gid}
                        ></ShareResultButton>
                    </Flex>
                </Center>
            </Flex>
        </Flex>
    );
}