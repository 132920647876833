import React from "react";
import { Box, Center, Container, Flex, Icon, Tab, TabList, Tabs, Text, VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

export function RightPanel(props) {
    var { tabIndex, updateTabIndexfunc, mapTabIndexfunc, tabList, ...other } = props;

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    return (
        <VStack spacing={{ base: '4px', lg: '20px' }} {...other} width="full">
            <Center width="full">
                <Container maxW='1000px' width="full">
                    <Tabs width="100%" index={tabIndex} onChange={(index) => {
                        dispatch(updateTabIndexfunc(index));
                    }}>
                        <Center>
                            <TabList>
                                {tabList.map((item, index) => {
                                    return (
                                        <Tab key={index} px={{ base: "5px", md: "16px" }} display={item.display ? "block" : "none"}>
                                            <Flex flexDirection={{ base: 'column', md: 'row', lg: 'row' }}>
                                                <Center>
                                                    <Icon as={item.icon} fontSize={'lg'} mr={'0.5rem'} />
                                                </Center>
                                                <Center>
                                                    <Text fontSize={{ base: 'xs', sm: 'sm', md: 'md' }}>{t(item.showName)}</Text>
                                                </Center>
                                            </Flex>
                                        </Tab>
                                    );
                                })}
                            </TabList>
                        </Center>
                    </Tabs>
                    <Flex flexDirection={"row"} marginTop={5}>
                        {tabList.map((item, index) => {
                            return mapTabIndexfunc(tabIndex) == item.title && (
                                <Box width="100%" key={index}>
                                    {item.component}
                                </Box>
                            );
                        })}
                    </Flex>
                </Container>
            </Center>
        </VStack>
    );
}
