import React from "react";
import {Box, chakra, Flex,} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import getTimeDescription from "../utils/getTimeDescription";

export function CreditHistoryRow(props) {
    const {t, i18n} = useTranslation();

    const getEventName = (type, gen_type) => {
        if (type === "top_up" || type === "forever_credits") {
            return t('purchase_credits');
        } else if (type === "generation") {
            if (gen_type === null || gen_type === undefined || gen_type !== "ai_chat") {
              return t('grading') + "/" + t('generation');
            } else {
              return t('ai_chat')
            }
        } else if (type === "registration") {
            return t('register_bonus');
        } else if (type === "admin_top_up") {
            return t('others');
        } else if (type === "referral") {
            return t('ref_bonus');
        } else if (type === "monthly_credits") {
            return t("subscription_credit")
        } else if (type === "limited_credits") {
            return t("time_limited_credit")
        } else if (type === "expired_credits") {
            return t("expired_credit")
        } else if (type === "expired_limited_credits") {
            return t("expired_limited_credits")
        } else if (type === "gid_credits") {
            return t("gid_credits")
        } else if (type === "referer_credits") {
            return t("referer_credits")
        } else if (type === "expired_registration_credits") {
            return t("expired_registration_credits")
        } else if (type === "expired_referer_credits") {
            return t("expired_referer_credits")
        }

        return type;

    };

    let event = props.data;


    return (
        <Flex
            direction={'column'}
            textAlign={'left'}
            justifyContent={'space-between'}
            p={1}
        >
            {(i18n.language == "cn" && event.is_campus_certified) ?
                <Flex>
                    <chakra.p fontWeight={'bold'} fontSize={14}>
                        {getEventName(event.type, event?.gen_type)}(学生认证)
                    </chakra.p>
                    <Box flex={"1"}></Box>
                    <chakra.span
                        fontWeight={'medium'}
                        color={'gray.500'}>
                        (提示：邀请超过10人后请联系我们) {getTimeDescription(t, event.created_at)}
                    </chakra.span>
                </Flex>
                :
                <Flex>
                    <chakra.p fontWeight={'bold'} fontSize={14}>
                        {getEventName(event.type, event?.gen_type)}
                    </chakra.p>
                    <Box flex={"1"}></Box>
                    <chakra.span
                        fontWeight={'medium'}
                        color={'gray.500'}>
                        {getTimeDescription(t, event.created_at)}
                    </chakra.span>
                </Flex>
            }
            <chakra.p mb={0}
                      fontSize={'15px'}>
                {event.delta + " points"}
            </chakra.p>
        </Flex>
    );
}