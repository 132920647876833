import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
} from "@chakra-ui/react";
import AIChatHistory from '../views/Dashboard/AIChatHistory';

export default function ChatDrawer({ isOpen, onClose, character }) {
    return (
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size="full"
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerBody padding={0}>
                    <AIChatHistory 
                        character={character} 
                        onClose={onClose}
                    />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
} 