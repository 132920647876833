import React, {useEffect, useState} from "react";
import {Box, Button, Center, Flex, List, ListItem, NumberInput, NumberInputField, Text,} from "@chakra-ui/react";
import {useFilters, usePagination, useTable} from "react-table";
import {useGetBalanceQuery} from "../services/cmApi";

export function PaginatedTable(props) {
    const {data, filterProduct, children, ...rest} = props;
    let balance = useGetBalanceQuery({});

    const [filteredData, setFilteredData] = useState(data)
    useEffect(() => {
        if (filterProduct) {
            setFilteredData(data.filter(row => (row.product || "toefl") === filterProduct))
        } else {
            setFilteredData(data)
        }
    }, [props.filterProduct, props.data])

    const columns = React.useMemo(
        () => [
            {
                Header: "Item",
                columns: [
                    {
                        Header: "Item",
                        accessor: "item"
                    }
                ]
            }
        ],
        []
    );

    const {
        prepareRow,
        page,
        pageOptions,
        gotoPage,
        state: {pageIndex}
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: {pageIndex: 0, pageSize: props.pageSize || 5}
        },
        useFilters,
        usePagination
    );

    return (
        <Flex flexDirection="column" overflowY="auto" height="100%" width="full">
            <Box flex="1">
                <Flex flexDirection="row" width="full" m={4}>
                    <Box flex="1"></Box>
                    <Center>
                        <Button
                            variant='outline'
                            px="0"
                            onClick={() => {
                                gotoPage(pageIndex - 1);
                            }}
                        >
                            {"←"}
                        </Button>
                    </Center>
                    <Center>
                        <NumberInput
                            mx={2}
                            w={20}
                            min={1}
                            max={pageOptions.length}
                            onChange={(value) => {
                                const page = value ? value - 1 : 0;
                                gotoPage(page);
                            }}
                            value={pageIndex + 1}
                            defaultValue={pageIndex + 1}

                        >
                            <NumberInputField/>
                        </NumberInput>
                    </Center>
                    <Center>
                        <Button
                            variant='outline' mr={8}
                            px="0"
                            onClick={() => {
                                gotoPage(pageIndex + 1);
                            }}
                        >
                            {"→"}
                        </Button>
                    </Center>
                </Flex>
                <List width="100%">
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <ListItem
                                key={row.id || i}
                                width="full"
                                direction={{base: 'column-reverse', md: 'row'}}
                                p={5}
                                justifyContent={'space-between'}
                                position={'relative'}
                                bg='white'
                                borderTopWidth='1px' borderColor='gray.200'
                                pt={1} pb={1}>
                                <props.rowComponent
                                    data={row.original}
                                    onRowSelected={props.onRowSelected}
                                    balanceValue={(balance && balance.data && balance.data["balance"]) || 0
                                    }></props.rowComponent>
                            </ListItem>
                        );
                    })}
                </List>
                <Center>
                    <Flex alignItems="center">
                        <Text flexShrink="0" mr={8}>
                            <Text fontWeight="bold" as="span">
                                {pageIndex + 1}
                            </Text>{" "}
                            of{" "}
                            <Text fontWeight="bold" as="span">
                                {pageOptions.length}
                            </Text>
                        </Text>
                    </Flex>
                </Center>
            </Box>
        </Flex>
    );
}